<template>
<div>
  <section>
      <client-nav></client-nav>
  </section>
    <section class="section">
        <router-view></router-view>
    </section>
</div>
</template>

<script>
import ClientNav from '@/components/ClientNav'

export default {
    components: {
        "client-nav": ClientNav
    }
};
</script>